






















































































































































































































  .submitLogin {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
#registerText {
  color: #3f3356;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
#registerLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
